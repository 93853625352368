.card-link {
    text-decoration: none;
}

.offer-card-bg {
    background-color: #add8e630;
}

.text-primary-cust {
    color: #007BFF;
}

.card-heading {
    background-color: #0cd2ff;
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.offers-hr {
    margin-right: 0;
    margin-left: 0;
    border: 1px solid lightblue;
}