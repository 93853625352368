.card-link {
    text-decoration: none;
}

.offer-card-bg {
    background-color: #add8e630;
}

.text-primary-cust {
    color: #007BFF;
}

.card-heading {
    background-color: #0cd2ff;
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.offer-status {
    padding: 5px 15px;
    background-color: #0cd2ff;
    float: right;
    font-size: 15px;
    border-radius: 40px;
}