/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
                  width: 100%;
                  height: 100vh;
                  background: url(../../../public/assets/images/hero-bg.png) top center no-repeat;
                  background-size: cover;
                }
                
                .hero h1 {
                  margin: 0;
                  font-size: 48px;
                  font-weight: 700;
                  color: #012970;
                }
                
                .hero h2 {
                  color: #444444;
                  margin: 15px 0 0 0;
                  font-size: 26px;
                }
                
                .hero .btn-get-started {
                  margin-top: 30px;
                  line-height: 0;
                  padding: 20px 55px;
                  border-radius: 4px;
                  transition: 0.5s;
                  color: #fff;
                  background: #4154f1;
                  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
                  text-decoration: none;
                }
                
                .hero .btn-get-started span {
                  font-family: "Nunito", sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  letter-spacing: 1px;
                }
                
                .hero .btn-get-started i {
                  margin-left: 5px;
                  font-size: 18px;
                  transition: 0.3s;
                }
                
                .hero .btn-get-started:hover i {
                  transform: translateX(5px);
                }
                
                .hero .hero-img {
                  text-align: right;
                }
                
                @media (min-width: 1024px) {
                  .hero {
                    background-attachment: fixed;
                  }
                }
                
                @media (max-width: 991px) {
                  .hero {
                    height: auto;
                    padding: 120px 0 60px 0;
                  }
                
                  .hero .hero-img {
                    text-align: center;
                    margin-top: 80px;
                  }
                
                  .hero .hero-img img {
                    width: 80%;
                  }
                }
                
                @media (max-width: 768px) {
                  .hero {
                    text-align: center;
                  }
                
                  .hero h1 {
                    font-size: 32px;
                  }
                
                  .hero h2 {
                    font-size: 24px;
                  }
                
                  .hero .hero-img img {
                    width: 100%;
                  }
                }
                /*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
                  color: #444444;
                  background: #fafbff;
                  padding: 30px;
                }
                
                .contact .info-box i {
                  font-size: 38px;
                  line-height: 0;
                  color: #4154f1;
                }
                
                .contact .info-box h3 {
                  font-size: 20px;
                  color: #012970;
                  font-weight: 700;
                  margin: 20px 0 10px 0;
                }
                
                .contact .info-box p {
                  padding: 0;
                  line-height: 24px;
                  font-size: 14px;
                  margin-bottom: 0;
                }
                
                .contact .php-email-form {
                  background: #fafbff;
                  padding: 30px;
                  height: 100%;
                }
                
                .contact .php-email-form .error-message {
                  display: none;
                  color: #fff;
                  background: #ed3c0d;
                  text-align: left;
                  padding: 15px;
                  margin-bottom: 24px;
                  font-weight: 600;
                }
                
                .contact .php-email-form .sent-message {
                  display: none;
                  color: #fff;
                  background: #18d26e;
                  text-align: center;
                  padding: 15px;
                  margin-bottom: 24px;
                  font-weight: 600;
                }
                
                .contact .php-email-form .loading {
                  display: none;
                  background: #fff;
                  text-align: center;
                  padding: 15px;
                  margin-bottom: 24px;
                }
                
                .contact .php-email-form .loading:before {
                  content: "";
                  display: inline-block;
                  border-radius: 50%;
                  width: 24px;
                  height: 24px;
                  margin: 0 10px -6px 0;
                  border: 3px solid #18d26e;
                  border-top-color: #eee;
                  animation: animate-loading 1s linear infinite;
                }
                
                .contact .php-email-form input,
                .contact .php-email-form textarea {
                  border-radius: 0;
                  box-shadow: none;
                  font-size: 14px;
                  border-radius: 0;
                }
                
                .contact .php-email-form input:focus,
                .contact .php-email-form textarea:focus {
                  border-color: #4154f1;
                }
                
                .contact .php-email-form input {
                  padding: 10px 15px;
                }
                
                .contact .php-email-form textarea {
                  padding: 12px 15px;
                }
                
                .contact .php-email-form button[type=submit] {
                  background: #4154f1;
                  border: 0;
                  padding: 10px 30px;
                  color: #fff;
                  transition: 0.4s;
                  border-radius: 4px;
                }
                
                .contact .php-email-form button[type=submit]:hover {
                  background: #5969f3;
                }
                
                @keyframes animate-loading {
                  0% {
                    transform: rotate(0deg);
                  }
                
                  100% {
                    transform: rotate(360deg);
                  }
                }
                
                /*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
                  background: #fff;
                  padding: 60px 0;
                }
                
                .team .member {
                  overflow: hidden;
                  text-align: center;
                  border-radius: 5px;
                  background: #fff;
                  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
                  transition: 0.3s;
                }
                
                .team .member .member-img {
                  position: relative;
                  overflow: hidden;
                }
                
                .team .member .member-img:after {
                  position: absolute;
                  content: "";
                  left: 0;
                  bottom: 0;
                  height: 100%;
                  width: 100%;
                  background: url(../../../public/assets/images/team-shape.svg) no-repeat center bottom;
                  background-size: contain;
                  z-index: 1;
                }
                
                .team .member .social {
                  position: absolute;
                  right: -100%;
                  top: 30px;
                  opacity: 0;
                  border-radius: 4px;
                  transition: 0.5s;
                  background: rgba(255, 255, 255, 0.3);
                  z-index: 2;
                }
                
                .team .member .social a {
                  transition: color 0.3s;
                  color: rgba(1, 41, 112, 0.5);
                  margin: 15px 12px;
                  display: block;
                  line-height: 0;
                  text-align: center;
                }
                
                .team .member .social a:hover {
                  color: rgba(1, 41, 112, 0.8);
                }
                
                .team .member .social i {
                  font-size: 18px;
                }
                
                .team .member .member-info {
                  padding: 10px 15px 20px 15px;
                }
                
                .team .member .member-info h4 {
                  font-weight: 700;
                  margin-bottom: 5px;
                  font-size: 20px;
                  color: #012970;
                }
                
                .team .member .member-info span {
                  display: block;
                  font-size: 14px;
                  font-weight: 400;
                  color: #aaaaaa;
                }
                
                .team .member .member-info p {
                  font-style: italic;
                  font-size: 14px;
                  padding-top: 15px;
                  line-height: 26px;
                  color: #5e5e5e;
                }
                
                .team .member:hover {
                  transform: scale(1.08);
                  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
                }
                
                .team .member:hover .social {
                  right: 8px;
                  opacity: 1;
                }