:root {
    --primary: #71348c;
    --primary1: #347720;
    --primary2: #00a884;
    --color1: #136552;
    --secondary: #FF6922;
    --light: #EFFDF5;
    --dark: #2e556a;

}

.navbar {

    background-color: #0cd2ff;
    box-shadow: 0 3px 4px -2px gray;
}

.navbar-nav {
    flex-direction: row-reverse !important;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000000;
    font-weight: 700;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #000000;
}

.nav-bar {
    position: relative;
}

.navbar-light .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 10px 0px;
    color: #000000;
    font-size: 18px;
    /* text-transform: uppercase; */

}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--primary2);
}

.navbar-light .navbar-nav .nav-link.active {
    color: var(--light);
}

@media (max-width: 991.98px) {
    .nav-bar {
        margin: 0;
        padding: 0;
    }

    .navbar-light .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

.nav-logo {
    max-width: 200px;
    height: auto;
}