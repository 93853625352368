.profile-tab-nav {
	box-shadow: none !important;
}

.nav-pills a.nav-link {
	margin-bottom: 5px;
	padding: 12px 20px;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	color: #333;
}

.img-circle img {
	height: 100px;
	width: 100px;
	border-radius: 100%;
	border: 5px solid #fff;
}

.nav-link {
	border-radius: 25px !important;
	font-size: 15px;
	font-weight: 600;
}

.active-tab {
	background-color: #0CD2FF;
}