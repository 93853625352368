/* .my-cards {
                  display: flex;
                  flex-wrap: wrap;
                } */
                
                /* .card-inner{
                  flex: 1 0 20%;
                  position: relative;
                  margin: .5em;
                  padding: 2em;
                  min-height: 4em;
                  background: white;
                  border: 3px solid grey;
                }
                
                .active { border-color: orangered; }
                input[type="checkbox"] {
                  position: absolute;
                  top: .5em;
                  right:.5em;
                }
                
                @media (pointer: coarse) { 
                  input[type="checkbox"] {
                    height: 2em;
                    width: 2em;
                  }
                }
                
                
                @media (hover: hover) {
                              input[type="checkbox"] { z-index: -1 }
                  .card:hover input[type="checkbox"],
                              input[type="checkbox"]:focus,
                              input[type="checkbox"]:checked { z-index: auto }
                }
                .card-inner .card-image img{

                  border-radius: 100%;

                } */
                .my-check-box {
                  list-style-type: none;
                }
                
               .my-check-box li {
                  display: inline-block;
                }
                
               .my-check-box li input[type="checkbox"][id^="myCheckbox"] {
                  display: none;
                }
                
               .my-check-box li label {
                  border: 1px solid #fff;
                  padding: 10px;
                  display: block;
                  position: relative;
                  margin: 10px;
                  cursor: pointer;
                }
                
                .my-check-box li label:before {
                  background-color: white;
                  color: white;
                  content: " ";
                  display: block;
                  border-radius: 50%;
                  border: 1px solid grey;
                  position: absolute;
                  top: -5px;
                  left: -5px;
                  width: 25px;
                  height: 25px;
                  text-align: center;
                  line-height: 28px;
                  transition-duration: 0.4s;
                  transform: scale(0);
                }
                
                .my-check-box li label img {
                  height: 150px;
                  width: 150px;
                  transition-duration: 0.2s;
                  transform-origin: 50% 50%;
                }
                
                .my-check-box li :checked + label {
                  border-color: #ddd;
                }
                
                .my-check-box li :checked + label:before {
                  content: "✓";
                  background-color: #0D6EFD;
                  transform: scale(1);
                }
                
                .my-check-box li :checked + label img {
                  transform: scale(0.9);
                  /* box-shadow: 0 0 5px #b4b4b4; */
                  z-index: -1;
                }